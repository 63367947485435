








































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { APPIDS } from '@/enums/';
import { SystemCourse, CourseBasic } from '@/types/course.type';
import DialogComponent from '@/components/dialog.component.vue';
import CoursesProvider from '@/providers/courses.provider';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    DialogComponent,
  },
})
export default class SystemCourseAddEditDialog extends mixins(Toaster) {
  @Prop(Boolean) readonly value!: boolean;

  @Prop(Boolean) readonly isSCU!: boolean;

  @Prop(String) readonly scopeId!: string;

  @Prop({ default: false }) readonly persistent!: boolean;

  @Prop(Number) courseId!: number;

  appId = APPIDS.COURSES;

  course!: CourseBasic;

  err = '';

  loading = false;

  showAlert = false;

  validForm = false;

   $options!: {
    filters: {
      servertranslate;
    };
  };

  servertranslate = this.$options.filters.servertranslate;

  courseForm: SystemCourse = {
    arCourseName: '',
    enCourseName: '',
    arStudyPlan: '',
    enStudyPlan: '',
    studyPlanId: '',
    courseCode: '',
  };

  get rules() {
    return {
      arCourseName: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
        (val: string) => val.trim().length >= 3 || this.$t('validations.minLength', { min: 3 }),
      ],
      enCourseName: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
        (val: string) => val.trim().length >= 3 || this.$t('validations.minLength', { min: 3 }),
      ],
      arStudyPlan: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
        (val: string) => val.trim().length >= 3 || this.$t('validations.minLength', { min: 3 }),
      ],
      enStudyPlan: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
        (val: string) => val.trim().length >= 3 || this.$t('validations.minLength', { min: 3 }),
      ],
      studyPlanId: [
        (val: string) => !!val || this.$t('validations.required'),
        (val: string) => val.trim().length <= 50
          || this.$t('validations.maxLength', { max: 50 }),
        (val: string) => val.trim().length >= 3 || this.$t('validations.minLength', { min: 3 }),
      ],
      courseCode: this.isSCU
        ? [
          (val: string) => /^[0-9]{2}$/.test(val) || this.$t('validations.codePattern'),
        ]
        : [
          (val: string) => val.trim().length <= 50 || this.$t('validations.maxLength', { max: 50 }),
          (val: string) => !val || val.trim().length >= 3 || this.$t('validations.minLength', { min: 3 }),
        ],
    };
  }

  get showDialog() {
    return this.value;
  }

  set showDialog(value) {
    this.$emit('input', value);
  }

  async mounted() {
    if (this.courseId) {
      this.loadCourse();
    }
  }

  async loadCourse() {
    const result = await CoursesProvider.getCourseEdit(
      this.appId,
      this.courseId,
    );
    this.fillCourseForm(result.course);
  }

  fillCourseForm(course: CourseBasic) {
    this.courseForm = {
      arCourseName: course.courseName?.ar ?? '',
      enCourseName: course.courseName?.en ?? '',
      arStudyPlan: course.planName?.ar ?? '',
      enStudyPlan: course.planName?.en ?? '',
      studyPlanId: course.studyPlanId,
      courseCode: course.courseCode ? course.courseCode : '',
    };
  }

  async save() {
    try {
      this.showAlert = false;
      this.loading = true;
      if (this.courseId) {
        const result = await CoursesProvider.editCourse(
          this.appId,
          this.courseId,
          {
            ...this.courseForm,
          },
        );
        this.successToaster(result.message);
      } else {
        await CoursesProvider.addCourse(this.appId, {
          ...this.courseForm,
          scopeId: this.$store.state.scope.selectedScopeId,
        });
      }
      this.loading = false;
      this.showDialog = false;
      this.$emit('saved', { ...this.courseForm });
      this.resetForm();
    } catch (err) {
      this.err = this.servertranslate(err);
      this.showAlert = true;
      this.loading = false;
    }
  }

  cancel() {
    this.showDialog = false;
    this.resetForm();
  }

  resetForm() {
    this.courseForm = {
      arCourseName: '',
      enCourseName: '',
      arStudyPlan: '',
      enStudyPlan: '',
      studyPlanId: '',
      courseCode: '',
    };
    (this.$refs.courseForm as Vue & {
      resetValidation: () => boolean;
    }).resetValidation();
  }
}
