











































































































































































import { Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import {
  BasicOption,
  SystemCourseFilters,
  ExcelFile,
  SystemCourse,
} from '@/types/course.type';
import { APPIDS } from '@/enums/';
import DialogComponent from '@/components/dialog.component.vue';
import SystemCourseAddEditDialog from '@/components/courses/systemCourseAddEdit.dialog.vue';
import CoursesProvider from '@/providers/courses.provider';
import ScopeComponent from '@/components/scope.component.vue';
import { mixins } from 'vue-class-component';
import { Lang } from '../../types/general.type';
import Toaster from '../../mixins/toaster.mixin';

@Component({
  components: {
    ScopeComponent,
    DialogComponent,
    SystemCourseAddEditDialog,
    SystemCourseDetailsComponent: () => import('@/components/courses/systemCourseDetails.component.vue'),
    ServiceCoursesDetailsComponent: () => import('@/components/courses/serviceCoursesDetails.component.vue'),
    FileCoursesDetailsComponent: () => import('@/components/courses/fileCoursesDetails.component.vue'),
  },
})
export default class CoursesAdd extends mixins(Toaster) {
  fileFormats =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  appId = APPIDS.COURSES;

  loading = {
    getFilters: true,
    save: false,
  };

  validForm = false;

  showExcelImportDialog = false;

  showSystemAddDialog = false;

  academicYears: BasicOption[] = [];

  filtersInputs: SystemCourseFilters = {
    selectedAddMethod: '',
    selectedAcademicYear: '',
  };

  showDetails = false;

  /**
   * @hossam
   * give atype for this and remove linte disable
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */

  detailsData: any = {};

  showValidation = false;

  validationMessage: TranslateResult | string = '';

  currentDetailsComponent = '';

  showScopeError = false;

  showImportConfirm = false;

  isSCU = false;

  $refs!: {
    scopeBtn: ScopeComponent;
    file: HTMLInputElement;
  };

  get addMethods(): BasicOption[] {
    return this.isSCU
      ? [
        { id: '3', name: this.$t('labels.addFromSystem') },
      ]
      : [
        { id: '1', name: this.$t('labels.addFromService') },
        { id: '2', name: this.$t('labels.addFromExcel') },
        { id: '3', name: this.$t('labels.addFromSystem') },
      ];
  }

  get disableSave() {
    return (
      this.loading.save
      || !this.filtersInputs.selectedAddMethod
      || (this.filtersInputs.selectedAddMethod === '1'
        && !this.filtersInputs.selectedAcademicYear)
    );
  }

  get showMissingScopeError() {
    return !this.$store.state.scope.selectedScopeId && this.showScopeError;
  }

  mounted() {
    this.getFilters();
    this.resetScope();
    this.$store.commit('setTreeLastLevel', true);
  }

  resetScope() {
    this.$store.commit('resetScope');
  }

  async getFilters() {
    try {
      this.loading.getFilters = true;

      const { academicYears } = await CoursesProvider.getAddFilters(this.appId);
      this.loading.getFilters = false;
      this.academicYears = academicYears;
    } catch (err) {
      this.loading.getFilters = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  @Watch('$store.state.scope.selectedScopeId')
  async getFoundation(value: string): Promise<void> {
    const { isSCU } = await CoursesProvider.getFoundation(
      this.appId,
      value,
    );
    this.isSCU = isSCU;
    this.filtersInputs.selectedAddMethod = '';
  }

  addCourse() {
    this.showDetails = false;
    if (!this.$store.state.scope.selectedScopeId) {
      this.showScopeError = true;
      this.$refs.scopeBtn.scopeClick();
      ScopeComponent.setFocus();
      return;
    }

    this.showScopeError = false;
    if (this.filtersInputs.selectedAddMethod === '1') {
      this.currentDetailsComponent = 'ServiceCoursesDetailsComponent';
      this.showImportConfirm = true;
    } else if (this.filtersInputs.selectedAddMethod === '2') {
      this.currentDetailsComponent = 'FileCoursesDetailsComponent';
      this.showExcelImportDialog = true;
    } else {
      this.currentDetailsComponent = 'SystemCourseDetailsComponent';
      this.showSystemAddDialog = true;
    }
  }

  goBack() {
    this.$router.go(-1);
  }

  async serviceImport() {
    try {
      this.showImportConfirm = false;
      this.loading.save = true;

      const {
        addedCourses,
        editedCourses,
        inactiveCourses,
      } = await CoursesProvider.importFromService(this.appId, {
        scopeId: this.$store.state.scope.selectedScopeId,
        acadmicYearID: this.filtersInputs.selectedAcademicYear,
      });
      this.loading.save = false;
      this.detailsData = { addedCourses, editedCourses, inactiveCourses };
      this.showDetails = true;
    } catch (err) {
      this.errorToaster(err.message as Lang);
      this.loading.save = false;
    }
  }

  addExcel() {
    this.showExcelImportDialog = false;
    this.$refs.file.click();
  }

  async excelImport(event) {
    try {
      const excelFile = event.target.files[0];
      if (!this.validateExcelFile(excelFile)) {
        this.resetFileInput();
        return;
      }

      const reqData = new FormData();
      reqData.append(
        'scopeId',
        this.$store.state.scope.selectedScopeId as string,
      );
      reqData.append('file', excelFile);

      this.loading.save = true;
      const { addedCourses } = await CoursesProvider.importFromExcel(
        this.appId,
        reqData,
      );
      this.loading.save = false;
      this.detailsData = { addedCourses };
      this.showDetails = true;
      this.resetFileInput();
    } catch (err) {
      this.loading.save = false;
      this.resetFileInput();
    }
  }

  resetFileInput() {
    const fileInput = this.$refs.file;
    fileInput.type = 'text';
    fileInput.type = 'file';
  }

  validateExcelFile(file: ExcelFile) {
    if (!file) {
      return false;
    }
    const validSize = 5 * 1024 * 1024;
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];

    if (!validTypes.includes(file.type as string)) {
      this.validationMessage = this.$t('validations.invalidFileType');
      this.showValidation = true;
      return false;
    }
    if ((file.size as number) > validSize) {
      this.validationMessage = this.$t('validations.invalidFileSize', {
        max: 5,
      });
      this.showValidation = true;
      return false;
    }
    return true;
  }

  showResults(course: SystemCourse) {
    this.detailsData = course;
    this.showDetails = true;
  }
}
